import { IconChevronLeft } from '@frameio/vapor-icons';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Collapse,
  CollapseTrigger,
  CollapseContent,
  Tabs,
  useTab,
  TabList,
} from '@frameio/vapor';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'; // eslint-disable-line import/no-extraneous-dependencies
import { BLOCKS, MARKS } from '@contentful/rich-text-types'; // eslint-disable-line import/no-extraneous-dependencies
import { sizes } from '../../utils/breakpoints';
import SectionHeader from './section-header';
import { track } from '@frameio/developer-components';

const Header = styled(SectionHeader)({
  h2: {
    color: '#FFFFFF',
  },
  span: {
    color: '#FFFFFF',
  },
});

const StyleTabList = styled(TabList)({
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: '50px',
  '> div': {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '350px',
  },
  margin: '0 auto',
  background: 'none !important',
  borderRadius: 0,
});

const Wrapper = styled.div({
  backgroundColor: '#0F0F14',
  padding: '30px var(--page-margin) 40px var(--page-margin)',
});

const StyledCollapseContent = styled(CollapseContent)({
  div: {
    color: '#8B8A8E',
    fontSize: '16px',
    lineHeight: '24px',
    display: 'inline-block',
    padding: '5px 0',
  },
  a: {
    outline: 'none',
    color: '#FFF',
  },
});

const StyledCollapseTrigger = styled(CollapseTrigger)({
  cursor: 'pointer',
  svg: {
    transition: 'transform 0.3s ease',
  },
  '&[aria-expanded="true"] svg': {
    transform: 'rotateZ(-90deg)',
  },
});

const FaqCollapse = ({ question, answer, index }) => (
  // eslint-disable-next-line
  <div
    onClick={() => track('homepage-faq-link-clicked')}
    style={{
      borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
      padding: `${index !== 0 ? '24px 0' : '0 0 24px 0'}`,
      // Hacky fix for Safari rendering bug
      // https://stackoverflow.com/questions/9983520/webkit-animation-is-leaving-junk-pixels-behind-on-the-screen
      boxShadow: '0 0 1px rgba(0, 0, 0, 0.05)',
    }}
  >
    <Collapse>
      <StyledCollapseTrigger type="">
        <div
          style={{
            padding: '5px 0',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ maxWidth: '90%' }}>
            <span
              style={{ color: '#FFFFFF', fontSize: '24px', lineHeight: '30px' }}
            >
              {question}
            </span>
          </div>
          <div
            style={{
              margin: 'auto 0',
              height: '100%',
            }}
          >
            <IconChevronLeft color="rgba(255, 255, 255, 0.5)" />
          </div>
        </div>
      </StyledCollapseTrigger>
      <StyledCollapseContent>
        <div>{answer}</div>
      </StyledCollapseContent>
    </Collapse>
  </div>
);

FaqCollapse.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.node.isRequired, // eslint-disable-line react/forbid-prop-types
  index: PropTypes.number.isRequired,
};

const CustomTabStyle = styled.div({
  textAlign: 'center',
  fontSize: '16px',
  lineHeight: '20px',
  display: 'inline-block',
  letterSpacing: '0.06em',
  textTransform: 'uppercase',
  color: '#EBEFFF',
  paddingBottom: '10px',
  outline: 'none',
  cursor: 'pointer',
  position: 'relative',

  ':after': {
    content: '""',
    display: 'block',
    height: '2.5px',
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },

  '&[aria-selected="true"]': {
    ':after': {
      background: `
        linear-gradient(
          90deg,
          #8FC8F2 10.98%,
          #DC30EF 33.82%,
          #2C1EE2 81.71%
        )`,
    },
  },
});

const CustomTab = ({ children }) => {
  const ref = useRef();
  const { bind } = useTab(ref);
  return (
    <CustomTabStyle ref={ref} {...bind}>
      {children}
    </CustomTabStyle>
  );
};

CustomTab.propTypes = {
  children: PropTypes.node.isRequired,
};

const FAQIndex = ({ data }) => {
  const [value, setValue] = useState(0);
  const sections = ['General', 'C2C'];

  const faqs = React.useMemo(
    () =>
      data.map((d) => ({
        ...d,
        answer: documentToReactComponents(JSON.parse(d.answer.raw), {
          renderMark: {
            [MARKS.BOLD]: (text) => <b>{text}</b>,
          },
          renderNode: {
            [BLOCKS.PARAGRAPH]: (_node, children) => <p>{children}</p>,
          },
        }),
      })),
    [data]
  );

  return (
    <Wrapper>
      <Header>
        <h2>Frequently Asked Questions</h2>
      </Header>
      <div>
        <Tabs
          value={value}
          onChange={(v) => {
            setValue(v);
            track('homepage-faq-section-clicked');
          }}
        >
          <StyleTabList shouldRenderSlider={false}>
            <CustomTab>general api</CustomTab>
            <CustomTab>camera to cloud</CustomTab>
          </StyleTabList>
          <div style={{ maxWidth: sizes.md, margin: 'auto' }}>
            {faqs
              .filter(({ section }) => section === sections[value])
              .map(({ question, answer }, index) => (
                <FaqCollapse
                  key={question}
                  question={question}
                  answer={answer}
                  index={index}
                />
              ))}
          </div>
        </Tabs>
      </div>
    </Wrapper>
  );
};

FAQIndex.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.shape({
        raw: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
};

export default FAQIndex;

import React from 'react';
import { Button } from '@frameio/vapor';
import { StaticImage } from 'gatsby-plugin-image';
import { IconArrowRightCircle } from '@frameio/vapor-icons';
import styled from 'styled-components';
import {
  track,
  redirectToAuthPortal,
  isAuthenticated,
} from '@frameio/developer-components';

import breakpoints, { sizes } from '../../utils/breakpoints';

const Container = styled.div({
  overflowX: 'hidden',
  display: 'flex',
  maxWidth: `${sizes.lg}px`,
  margin: '0 auto',
  justifyContent: 'space-between',
  padding: '0px var(--page-margin)',
  paddingBottom: '40px',
  flexDirection: 'column',
  [breakpoints.md]: {
    flexDirection: 'row',
    padding: '40px var(--page-margin)',
  },
});

const Header = styled.div({
  width: '100%',
  [breakpoints.md]: {
    width: '50%',
  },
  h1: {
    textAlign: 'center',
    color: '#000000',
    '@media (max-width: 768px)': {
      marginBottom: '0',
    },
    [breakpoints.md]: {
      textAlign: 'left',
    },
  },
  span: {
    fontSize: '18px',
    lineHeight: '26px !important',
    display: 'none',
    color: '#5b5b5b',
    width: '85%',
    [breakpoints.sm]: {
      margin: 'auto',
      display: 'block',
      textAlign: 'center',
    },
    [breakpoints.md]: {
      textAlign: 'left',
      margin: '0',
    },
  },
});

const ImgWrapper = styled.div({
  marginRight: 'calc(-1.2*var(--page-margin))',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  [breakpoints.md]: {
    width: '60%',
    maxWidth: '700px',
    marginRight: 'calc(-1.2*var(--page-margin))',
  },
  [breakpoints.xlg]: {
    marginRight: 0,
  },
});

const ButtonWrapper = styled.div({
  display: 'flex',
  margin: '15px 0',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  [breakpoints.xs]: {
    button: {
      margin: '5px 0',
    },
  },
  [breakpoints.sm]: {
    flexDirection: 'column !important',
    justifyContent: 'center',
    margin: '30px 0',
    button: {
      margin: '0 20px 0 0px',
    },
  },
  '@media (min-width: 400px)': {
    justifyContent: 'center',
    flexDirection: 'row !important',
  },
  [breakpoints.md]: {
    flexDirection: 'row !important',
    justifyContent: 'flex-start',
    button: {
      margin: '10px 0',
    },
  },
});

const StyledButton = styled(Button)({
  width: '112px',
  height: '42px',
  fontWeight: '500',
  fontSize: '14px',
  textTransform: 'uppercase',
});

const SignInButton = styled(StyledButton)({
  marginRight: '20px !important',
});

const StartBuildingBtn = styled(StyledButton)({
  color: '#000000 !important',
  backgroundColor: 'white !important',
  width: 'fit-content',
  opacity: 1,
  margin: '10px 0 10px 0px !important',
  [breakpoints.md]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  transition: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
  transitionDuration: '.3s',
  ':hover': {
    opacity: '0.8',
    svg: {
      scale: '1.1',
    },
  },
});

const StyledArrowButton = styled(IconArrowRightCircle)({
  scale: '1',
  transition: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
  transitionDuration: '.2s',
});

export default () => (
  <Container>
    <Header>
      <h1>Build your own workflow.</h1>
      <span>
        Frame.io customers and partners can use our REST APIs to create custom
        workflow solutions and app integrations. The new Camera to Cloud devices
        API enables you to embed the power of Frame.io in your hardware devices,
        extending the software tools and workflows that keep teams connected and
        working together.
      </span>
      <ButtonWrapper>
        {!isAuthenticated() && (
          <SignInButton onClick={redirectToAuthPortal} color="primary">
            Sign In
          </SignInButton>
        )}
        <a href="#start-building" style={{ textDecoration: 'none' }}>
          <StartBuildingBtn
            variant="ghost"
            iconAfter={<StyledArrowButton />}
            onClick={() => track('homepage-hero-link-clicked')}
          >
            Start building
          </StartBuildingBtn>
        </a>
      </ButtonWrapper>
    </Header>
    <ImgWrapper>
      <StaticImage
        loading="eager"
        placeholder="none"
        src="../../images/phonecode.png"
        alt="phone camera to cloud"
      />
    </ImgWrapper>
  </Container>
);

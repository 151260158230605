import styled from 'styled-components';
import breakpoints from '../../utils/breakpoints';

const SectionHeader = styled.div({
  textAlign: 'center',
  marginBottom: '40px',
  [breakpoints.md]: {
    marginBottom: '50px',
  },
  span: {
    fontSize: '18px',
  },
});

export default SectionHeader;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import breakpoints, { sizes } from '../../utils/breakpoints';
import iconsignal from '../../images/icon-signal.png';
import iconworkflow from '../../images/icon-workflow.png';
import iconpeople from '../../images/icon-people.png';
import SectionHeader from './section-header';
import { track } from '@frameio/developer-components';

const Header = styled(SectionHeader)({
  h2: {
    color: '#000000',
  },
  span: {
    display: 'inline-block',
    color: '#666A72',
    maxWidth: '450px',
  },
});

const IconTextBlockContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',

  [breakpoints.md]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  span: {
    display: 'inline-block',
  },
});
const Wrapper = styled.div({
  display: 'flex',
  margin: 'auto',
  flexDirection: 'column',
  maxWidth: `${sizes.lg}px`,
  padding: '30px var(--page-margin) 80px var(--page-margin)',
});

export default () => (
  <Wrapper>
    <Header>
      <h2>Let the work flow.</h2>
      <span>
        Easy to implement, our API helps bridge the gap between production and
        post.
      </span>
    </Header>
    <IconTextBlockContainer>
      <IconTextBlock
        icon={iconsignal}
        title="Cloud Device API"
        alt="Learn More"
        text="Use our implementation library to connect on-set hardware directly to Frame.io."
        path="/docs/device-integrations/getting-started-with-cloud-device-integrations"
      />
      <IconTextBlock
        icon={iconworkflow}
        title="Workflow Automation"
        alt="Learn More"
        text="Create event triggers to connect actions in Frame.io to external services, such as a new file upload or an asset approval."
        path="/docs/automations-webhooks/webhooks-overview"
      />
      <IconTextBlock
        icon={iconpeople}
        title="Manage Projects and Teams"
        alt="Learn More"
        text="Ensure every team member gets the right level of access to your media when they need it."
        path="/docs/workflows-admin/user-management"
      />
    </IconTextBlockContainer>
  </Wrapper>
);

const StyledIconTextBlock = styled.div({
  marginTop: '45px',
  display: 'grid',
  gridTemplateColumns: '40px auto',
  gridRow: 'auto auto',
  gridColumnGap: '10px',
  gridRowGap: '5px',

  h3: {
    color: '#000000',
  },
  span: {
    color: '#494751',
    fontSize: '16px',
    height: 'auto',
    marginBottom: '10px'
  },

  [breakpoints.md]: {
    width: '30%',
    span: {
      marginBottom: '0px',
      height: '100px',
    },
  },

  a: {
    textTransform: 'uppercase',
    color: '#000000',
    fontSize: '12px',
  }
});

const IconTextBlock = ({ icon, title, text, path }) => (
  <StyledIconTextBlock>
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <img src={icon} width="35px" height="auto" alt="icon" />
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <h3 style={{ fontSize: '22px' }}>{title}</h3>
    </div>
    <div style={{ gridColumn: 'span 2' }}>
      <div>
        <span>{text}</span>
      </div>
      <Link onClick={() => track('homepage-workflow-link-clinked')} to={path}>
        Learn more
      </Link>
    </div>
  </StyledIconTextBlock>
);

IconTextBlock.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

import {
  TextField,
  Button,
  TextareaField,
  MenuRadio,
  TextFieldLabel,
  Select,
} from '@frameio/vapor';
import { IconArrowRightCircle, IconChevronDown } from '@frameio/vapor-icons';
import qs from 'query-string';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { track } from '@frameio/developer-components';
import breakpoints, { sizes } from '../../utils/breakpoints';
import SectionHeader from './section-header';

const NETLIFY_FORM_NAME = 'Cloud Device Intake Form';

const Header = styled(SectionHeader)({
  marginBottom: '0',
  h2: {
    color: '#FFFFFF',
    textAlign: 'center',
    lineHeight: '47px',
  },
  span: {
    textAlign: 'center',
    color: '#FFFFFF',
    display: 'block',
    width: '267px',
    margin: '0 auto',
  },
  [breakpoints.md]: {
    width: '55%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    h2: {
      fontSize: '40px !important',
      textAlign: 'left',
      margin: '0 !important',
    },
    span: {
      padding: '20px 0',
      color: '#FFFFFF',
      textAlign: 'left',
      margin: '0',
    },
  },
  '@media (max-width: 1000px)': {
    marginBottom: '40px !important',
  },
});

const Form = styled.form({
  width: '100%',
  label: {
    textTransform: 'uppercase',
    fontSize: '12px',
    lineHeight: '11px',
    letterSpacing: 1,
    color: '#FFFFFF',
  },
  [breakpoints.md]: {
    paddingLeft: '50px',
  },
});

const Columns = styled.div({
  display: 'grid',
  width: '100%',
  gridTemplateRows: '2fr auto',
  gridTemplateColumns: '100%',
  gridGap: '0',

  [breakpoints.sm]: {
    gridTemplateColumns: '47.5% 47.5%',
    gridGap: '0 5%',
  },
});

const StyledSelectButton = styled(Button)({
  height: '40px',
  marginTop: '2px',
  marginBottom: '20px',
  border: '1px solid #FFFFFF',
  borderRadius: '8px',
  background: 'transparent',

  display: 'flex',
  justifyContent: 'space-between',
  color: 'rgba(255, 255, 255, 0.5)',
  fontSize: '14px',
  fontWeight: 400,
  padding: '0 13px',
  width: '100%',
});

const StyledSelect = styled(Select)({
  div: {
    width: 'unset',
  },
});

const StyledTextField = styled(TextField)({
  alignSelf: ({ align }) => (align === 'left' ? 'start' : 'end'),
  height: '40px',
  marginTop: '2px',
  marginBottom: '20px',
  border: '1px solid #FFFFFF',
  borderRadius: '8px !important',
  padding: '0 5px',
  background: 'transparent !important',
  input: {
    caretColor: '#FFFFFF',
  },
  'input::placeholder': {
    color: 'rgba(255, 255, 255, 0.5)',
    fontSize: '14px',
  },
  ':not(:focus-within)': {
    boxShadow: 'none !important',
  },
});

const StyledLink = styled(Link)`
  display: inline-block;
  margin: auto;

  ${breakpoints.sm} {
    margin: auto 0;
  }
`;

const StyledTextAreaField = styled(TextareaField)({
  alignSelf: ({ align }) => (align === 'left' ? 'start' : 'end'),
  boxSizing: 'border-box',
  marginTop: '2px',
  height: '114px !important',
  border: '1px solid #FFFFFF !important',
  borderRadius: '8px !important',
  background: 'transparent !important',
  paddingTop: '8px',
  paddingLeft: '12px',
  paddingBottom: '8px',
  lineHeight: '22px',
  marginBottom: '8px !important',
  '@media (max-width: 1000px)': {
    marginBottom: '20px !important',
  },
  '::placeholder': {
    color: 'rgba(255, 255, 255, 0.5)',
    fontSize: '14px',
  },
  ':not(:focus-within)': {
    boxShadow: 'none !important',
  },
});

const StyledButton = styled(Button)({
  alignSelf: ({ align }) => (align === 'left' ? 'start' : 'end'),
  height: '42px',
  fontSize: '14px',
  textTransform: 'uppercase',
  color: '#000000 !important',
  backgroundColor: '#FFFFFF !important',
  letterSpacing: '0.05em',
  margin: '10px 0',
  width: '100%',
  cursor: ({ disabled }) => (disabled ? 'auto' : 'pointer'),
  transition: '1s ease',
});

const DocButton = styled(Button)`
  height: 42px;
  font-size: 14px;
  color: #ffffff !important;
  text-transform: uppercase;
  animation: fadeIn ease 1.5s;
  margin: 10px 0;
  padding: 0;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Container = styled.div({
  backgroundColor: '#0F0F14',
  width: '100%',
})

const Wrapper = styled.div({
  marginTop: '0px',
  margin: 'auto',
  maxWidth: sizes.lg,
  padding: '0px',
  display: 'flex',
  justifyContent: 'center',
  a: {
    textDecoration: 'none',
  },
  [breakpoints.md]: {
    padding: '20px var(--page-margin) 20px var(--page-margin)',
  }
});

const InnerWrapper = styled.div({
  width: '100%',
  maxWidth: sizes.lg,
  background:
    'linear-gradient(125deg, rgba(220,90,161,1) 0%, rgba(102,28,187,1) 75%)',
  display: 'flex',
  padding: '0 var(--page-margin) var(--page-margin) var(--page-margin)',
  flexDirection: 'column',
  [breakpoints.md]: {
    padding: '50px',
    marginBottom: '40px',
    borderRadius: '20px',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const C2CForm = ({ location }) => {
  const { register, handleSubmit, errors, control } = useForm({ mode: 'all' });

  const [submitState, setSubmitState] = useState('submit');

  const submitted = submitState === 'submitted';
  const submitting = submitState === 'submitting';

  const disabled = submitted || submitting;

  const onSubmit = (data) => {
    setSubmitState('submitting');

    data.industry = data.controlledIndustry;
    delete data.controlledIndustry;

    const requestConfig = {
      url: location.pathname,
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
    };

    const trackingProps = {
      devrel_c2c_form_first_name: data.firstname,
      devrel_c2c_form_last_name: data.lastname,
      devrel_c2c_form_email: data.email,
      devrel_c2c_form_company_name: data.company,
      devrel_c2c_form_industry: data.industry,
      devrel_c2c_form_api_usecase: data.details,
    };

    axios(requestConfig)
      .then(() => setSubmitState('submitted'))
      .catch(() => setSubmitState('submit'));

    track('homepage-c2c-form-submitted', trackingProps);
  };

  return (
    <Container>
      <Wrapper id="start-building">
        <InnerWrapper>
          <Header>
            <div>
              <h2>Register to become a Cloud Devices developer.</h2>
              <span>
                Submit this form to start building with our Cloud Devices API.
              </span>
            </div>
          </Header>
          <Form
            name={NETLIFY_FORM_NAME}
            data-netlify="true"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              ref={register}
              type="hidden"
              name="form-name"
              value={NETLIFY_FORM_NAME}
            />
            <input type="hidden" name="industry" ref={register} />
            <Columns>
              <StyledTextField
                align="left"
                name="firstname"
                label="First name"
                placeholder="Enter your first name"
                ref={register({ required: 'First name should not be blank' })}
                error={errors.firstname?.message}
              />
              <StyledTextField
                align="right"
                name="lastname"
                label="Last name"
                placeholder="Enter your last name"
                ref={register({ required: 'Last name should not be blank' })}
                error={errors.lastname?.message}
              />
              <StyledTextField
                align="left"
                name="email"
                label="Work email"
                placeholder="Enter your work email"
                ref={register({
                  required: 'Email should not be blank',
                  pattern: /^\S+@\S+$/i,
                })}
                error={errors.email && 'Email should be valid and not blank'}
              />
              <StyledTextField
                align="right"
                name="company"
                label="Company name"
                placeholder="Enter your company name"
                ref={register({ required: 'Company name should not be blank' })}
                error={errors.company?.message}
              />
            </Columns>
            <div>
              <div style={{ paddingBottom: '6px' }}>
                <TextFieldLabel htmlFor="select">Current Industry</TextFieldLabel>
              </div>
              <Controller
                control={control}
                name="controlledIndustry"
                as={
                  <StyledSelect
                    placement="bottom-end"
                    content={
                      <>
                        <MenuRadio name="Media and Entertainment">
                          Media and Entertainment
                        </MenuRadio>
                        <MenuRadio name="Agency">Agency</MenuRadio>
                        <MenuRadio name="Brand">Brand</MenuRadio>
                        <MenuRadio name="Education">Education</MenuRadio>
                        <MenuRadio name="Software Partner">
                          Software Partner
                        </MenuRadio>
                        <MenuRadio name="Hardware Partner">
                          Hardware Partner
                        </MenuRadio>
                      </>
                    }
                  >
                    {({ value, bind }) => (
                      <StyledSelectButton
                        {...bind}
                        iconAfter={<IconChevronDown size={16} />}
                        style={{
                          marginBottom: '16px',
                          border: '1px solid #ffff',
                          color: 'transparent !important',
                        }}
                      >
                        {value ? (
                          <span style={{ color: 'rgba(246, 246, 248, 0.9)' }}>
                            {value}
                          </span>
                        ) : (
                          'Select an Industry'
                        )}
                      </StyledSelectButton>
                    )}
                  </StyledSelect>
                }
              />
              <StyledTextAreaField
                rowsMin={4}
                rowMax={8}
                name="details"
                label="How do you plan to use our API?"
                placeholder="Enter details here"
                ref={register({ required: 'Details should not be blank' })}
                error={errors.details?.message}
              />
            </div>
            <Columns>
              <StyledButton type="submit" disabled={disabled} align="left">
                {
                  // eslint-disable-next-line no-nested-ternary
                  submitted
                    ? 'Submitted'
                    : submitting
                      ? 'Submitting...'
                      : 'Start Building'
                }
              </StyledButton>
              {submitted && (
                <StyledLink
                  align="right"
                  to="/docs/device-integrations/getting-started-with-cloud-device-integrations"
                >
                  <DocButton variant="link" iconAfter={<IconArrowRightCircle />}>
                    read the documentation
                  </DocButton>
                </StyledLink>
              )}
            </Columns>
          </Form>
        </InnerWrapper>
      </Wrapper>
    </Container>
  );
};

export default C2CForm;

C2CForm.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import iconlock from '../../images/icon-lock.png';
import iconfolder from '../../images/icon-folder.png';
import iconclip from '../../images/icon-clipboard.png';
import iconupload from '../../images/icon-upload.png';
import breakpoints, { sizes } from '../../utils/breakpoints';
import SectionHeader from './section-header';

const Header = styled(SectionHeader)({
  h2: {
    color: '#FFFFFF',
  },
  span: {
    color: 'white !important',
    display: 'inline-block',
    maxWidth: '450px',
  },
});

const ContentWrapper = styled.div({
  margin: 'auto',
  maxWidth: `${sizes.lg}px`,
  h3: {
    color: '#FFFFFF',
  },
  display: 'flex',
  flexDirection: 'column',
  '> div': {
    margin: '20px 0',

    [breakpoints.md]: {
      width: '45%',
    },
  },
  [breakpoints.md]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const ImageWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [breakpoints.md]: {
    maxWidth: '550px',
    paddingBottom: '30px',
  },
});

const TextBlockContainer = styled.div({
  display: 'grid',
  [breakpoints.lg]: {
    gridTemplateColumns: '100% !important',
  },
  [breakpoints.md]: {
    gridTemplateColumns: '100% !important',
  },
  [breakpoints.sm]: {
    gridTemplateColumns: '50% 50%',
    gridGap: '16px',
  },
  '> div': {
    margin: '20px 0',

    [breakpoints.md]: {
      margin: 0,
      marginBottom: '10px',
    },

    [breakpoints.lg]: {
      marginBottom: '20px',
    },
  },
});

const Wrapper = styled.div({
  backgroundColor: '#000000',
  padding: '30px var(--page-margin) 80px var(--page-margin)',
});

export default () => (
  <Wrapper>
    <Header>
      <h2>C2C Device Integration</h2>
      <span>
        Our reference implementations provide a fast and easy way to get started
        connecting your device to Frame.io.
      </span>
    </Header>
    <ContentWrapper>
      <ImageWrapper>
        <StaticImage
          src='../../images/diagram.png'
          alt="phone camera to cloud"
          placeholder="none"
        />
      </ImageWrapper>
      <TextBlockContainer>
        <TextBlock
          icon={iconlock}
          title="Managed Authorization"
          text="Our Authorization API implements the OAuth2 Device Authorization and Refresh Token Grant flows."
        />
        <TextBlock
          icon={iconfolder}
          title="Automatic file management"
          text="Media from your paired device is created within a Frame.io project and organized by date and device type."
        />
        <TextBlock
          icon={iconclip}
          title="Reference Implementation"
          text="Code samples walk you through each step of integrating your device to our Cloud Platform."
        />
        <TextBlock
          icon={iconupload}
          title="Upload Controls"
          text="The Cloud Devices  API supports pausing and resuming uploads from the Frame.io UI."
        />
      </TextBlockContainer>
    </ContentWrapper>
  </Wrapper>
);

const StyledTextBlock = styled.div({
  display: 'grid',
  gridTemplateColumns: '45px auto',
  gridRow: 'auto auto',
  gridColumnGap: '5px',
  gridRowGap: '0px',
  [breakpoints.md]: {
    gridTemplateColumns: '55px auto',
  },
  span: {
    color: '#8B8A8E',
    [breakpoints.sm]: {
      paddingRight: '100px',
    },
    [breakpoints.md]: {
      paddingRight: '0px',
    },
  },
});

const TextBlock = ({ icon, title, text }) => (
  <StyledTextBlock>
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
      <img src={icon} width="35px" height="auto" alt="icon" />
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
      <h3>{title}</h3>
    </div>
    <div />
    <div>
      <span>{text}</span>
    </div>
  </StyledTextBlock>
);

TextBlock.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

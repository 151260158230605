import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {
  Faq,
  Hero,
  C2CDeviceIntegration,
  Workflow,
  Form,
} from '../components/homepage';
import breakpoints from '../utils/breakpoints';

const Container = styled.main({
  h1: {
    fontWeight: 'normal',
    letterSpacing: '-1px',
    fontSize: '50px',
    lineHeight: '65px',
    [breakpoints.xlg]: {
      fontSize: '60px',
    },
  },
  h2: {
    fontSize: '36px',
    margin: '50px 0 20px 0',
    fontWeight: 'normal',
    lineHeight: '43.2px',
    letterSpacing: '-1px',
    [breakpoints.md]: {
      fontSize: '50px',
      lineHeight: '50px',
    },
  },
  h3: {
    margin: '20px 0',
    fontSize: '24px',
    lineHeight: '24px',
    fontWeight: 'normal',
  },
  span: {
    lineHeight: '24px',
    fontWeight: 'normal',
  },
});

const IndexPage = ({
  data: {
    allContentfulFaq: { nodes: faqs },
  },
  ...props
}) => (
  <Layout>
    <SEO
      title="Frame.io - Developer Site"
      description="Learn more about building with the Frame.io API using Developer Tokens, Webhooks, Custom Actions, and OAuth apps. "
      faqs={faqs}
    />
    <Container>
      <Hero />
      <C2CDeviceIntegration />
      <Workflow />
      <Faq data={faqs} />
      <Form {...props} />
    </Container>
  </Layout>
);

export const FAQQuery = graphql`
  query FAQs {
    allContentfulFaq {
      nodes {
        section
        question
        taxonomy
        answer {
          raw
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default IndexPage;
